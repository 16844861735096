import TagManager from 'react-gtm-module';

export default function setup() {
  let gtmParams = { gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID };

  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    gtmParams = { ...gtmParams, auth: 'Xs3Y9f7mZYit6GNq5BriMg', preview: 'env-6' };
  }

  return TagManager.initialize(gtmParams);
}
