import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import { polyfill } from '@/src/lib/polyfills';

function loadLocaleData(locale) {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (locale) {
    default:
      return import('@/lang/pt-BR.json');
  }
}

function LanguageProvider(props) {
  const { children, locale } = props;

  const messages = loadLocaleData(locale);

  polyfill(locale);

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale={locale}>
      {children}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
};

LanguageProvider.defaultProps = {
  locale: 'pt-BR',
};

export default LanguageProvider;
