import { useEffect } from 'react';

import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

const tagManagerOptions = {
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  ...(process.env.NODE_ENV !== 'production' && {
    auth: 'Xs3Y9f7mZYit6GNq5BriMg',
    preview: 'env-6',
  }),
};

function GTMProvider(props) {
  const { children } = props;

  useEffect(() => {
    TagManager.initialize(tagManagerOptions);
  }, []);

  return children;
}

GTMProvider.propTypes = {
  children: PropTypes.any,
};

export default GTMProvider;
