import { useEffect } from 'react';

import Head from 'next/head';

import { DefaultSeo } from 'next-seo';
import PropTypes from 'prop-types';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'aos/dist/aos.css';
import 'leaflet/dist/leaflet.css';
import 'swiper/css';
import GTMProvider from '@/src/containers/commons/GTMProvider';
import LanguageProvider from '@/src/containers/commons/LanguageProvider';
import SentryProvider from '@/src/containers/commons/SentryProvider';
import SWRProvider from '@/src/containers/commons/SWRProvider';
import gtmSetup from '@/src/lib/gtm/setup';

import SEO from '@/next-seo.config';

import '@/src/styles/globals.css';

function MyApp(props) {
  const { Component, pageProps, err } = props;

  useEffect(() => {
    setTimeout(() => {
      gtmSetup();
    }, 5000);
  }, []);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <SentryProvider>
      <LanguageProvider>
        <GTMProvider>
          <SWRProvider>
            <Head>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
              />
            </Head>
            <DefaultSeo
              dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'}
              dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'}
              canonical={process.env.NEXT_PUBLIC_WEBSITE}
              facebook={{ appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID }}
              {...SEO}
            />
            <Component {...pageProps} err={err} />
            {/* <Script
              id="ze-snippet"
              src="https://static.zdassets.com/ekr/snippet.js?key=156f0a2a-80f4-4634-b559-718d03cdbf54"
              strategy="lazyOnload"
            /> */}
          </SWRProvider>
        </GTMProvider>
      </LanguageProvider>
    </SentryProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  err: PropTypes.any,
};

export default MyApp;
