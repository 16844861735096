import axios from 'axios';

import sessionToken from './utils/sessionToken';

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ENTRYPOINT,
});

api.interceptors.request.use(
  config => {
    if (typeof window !== 'undefined') {
      const { token, expiry } = sessionToken();
      const { Authorization } = config.headers;

      if (expiry) {
        const expiryDate = new Date(expiry);
        const nowDate = new Date();
        const isExpired = nowDate.getTime() > expiryDate.getTime();

        if (isExpired) {
          sessionToken({ destroy: true });

          return config;
        }
      }

      if (token && !Authorization) {
        config.headers.Authorization = `Token ${token}`; //eslint-disable-line
      }
    }

    return config;
  },
  // eslint-disable-next-line no-undef
  error => Promise.reject(error),
);

export default api;
