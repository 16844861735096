import { urlHostResolver } from '@/src/lib/utils';

export const noIndex = {
  noindex: true,
  nofollow: true,
  robotsProps: {
    noarchive: true,
    nosnippet: true,
    notranslate: true,
    noimageindex: true,
    maxImagePreview: 'none',
  },
};

const config = {
  title: undefined,
  titleTemplate: '%s | Eskolare',
  defaultTitle: 'Eskolare',
  description:
    'Compre tudo para educação em um único lugar! Tornamos a experiência de compra de produtos e serviços educacionais simples, prática e conveniente. Feito para você!',
  openGraph: {
    type: 'website',
    locale: 'pt_BR',
    images: [
      {
        url: urlHostResolver('/images/seo-default.png'),
        width: 1069,
        height: 953,
        alt: 'Eskolare',
      },
    ],
  },
  twitter: {
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    // Assets can be generated at https://realfavicongenerator.net/
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
      sizes: '180x180',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      color: '#0aadff',
      href: '/safari-pinned-tab.svg',
    },
  ],
  additionalMetaTags: [
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-title', content: 'Eskolare' },
    { name: 'application-name', content: 'Eskolare' },
    { name: 'msapplication-TileColor', content: '#ffffff' },
    { name: 'theme-color', content: '#ffffff' },
  ],
};

export default config;
