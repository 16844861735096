import PropTypes from 'prop-types';

import { init } from '@/src/lib/sentry';

init();

function SentryProvider(props) {
  const { children } = props;

  return children;
}

SentryProvider.propTypes = {
  children: PropTypes.any,
};

export default SentryProvider;
